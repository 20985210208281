// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-carrito-jsx": () => import("./../../../src/pages/carrito.jsx" /* webpackChunkName: "component---src-pages-carrito-jsx" */),
  "component---src-pages-carrito-pdf-jsx": () => import("./../../../src/pages/carrito-pdf.jsx" /* webpackChunkName: "component---src-pages-carrito-pdf-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-category-template-jsx": () => import("./../../../src/templates/categoryTemplate.jsx" /* webpackChunkName: "component---src-templates-category-template-jsx" */),
  "component---src-templates-page-template-jsx": () => import("./../../../src/templates/pageTemplate.jsx" /* webpackChunkName: "component---src-templates-page-template-jsx" */),
  "component---src-templates-product-template-jsx": () => import("./../../../src/templates/productTemplate.jsx" /* webpackChunkName: "component---src-templates-product-template-jsx" */)
}

