// ** @jsx jsx *
import React, { createContext, useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import search from "../../lib/search"
import Modal from "../ui/Modal.jsx"
import { jsx, ThemeProvider, Text, Button, Badge } from "theme-ui"
import { IoMdClose } from "react-icons/io"
import { FiShoppingCart } from "react-icons/fi"
import MiniImages from "../ui/MiniImages.jsx"
import "../../styles/master.less"
import theme from "../../gatsby-plugin-theme-ui/theme"
import countapi from "countapi-js"
import ReactPixel from "../../lib/react-facebook-pixel"
export const CartContext = createContext({
  cart: [],
  setCart: () => null,
})
export const UserDataContext = createContext({
  userData: {},
  setUserData: () => null,
})
export const DataGetContext = createContext({
  DataGet: {},
  setDataGet: () => null,
})

export const Provider = ({ children }) => {
  const [cart, setCart] = useState([])
  const [dataGet, setDataGet] = useState({})
  const [userData, setUserData] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [lastDate, setLastDate] = useState(false)
  const closeModal = () => {
    setCart([])
    typeof window !== "undefined" && window.localStorage.removeItem("lastOrder")
    setModalOpen(false)
  }
  const {
    allSanityProduct,
    sanitySetting,
    sanityUserInterface,
    sanityIntegration,
  } = useStaticQuery(graphql`
    query productContext {
      sanitySetting {
        money
      }
      sanityUserInterface {
        customColors {
          background
          colorPrimary
          colorSecondary
          theme
        }
      }
      sanityIntegration {
        facebookPixel {
          pixelId
          currency
        }
      }
      allSanityProduct(sort: { order: ASC, fields: [title] }) {
        nodes {
          id
          title
          slug
          description
          sku
          price
          img {
            asset {
              metadata {
                dimensions {
                  height
                  width
                }
              }
              url
              fluid(maxWidth: 800) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
              fixed(height: 50, width: 50) {
                base64

                width
                height
                src
                srcSet
                srcWebp
                srcSetWebp
              }
            }
            hotspot {
              height
              width
              x
              y
            }
          }

          categories {
            id
            title
            slug
            icon {
              asset {
                fluid {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                }
                fixed(height: 25, width: 25) {
                  base64

                  width
                  height
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                }
              }
            }
          }

          quantityOptions {
            active
            hiddenAddQuantity
            quantityStep
          }
          multimediaOptions {
            active
            fyuse
            youtube
            listImages {
              asset {
                fluid(maxWidth: 800) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                }
                fixed(height: 50, width: 50) {
                  base64

                  width
                  height
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                }
              }
            }
          }
          otherOptionsProduct {
            hiddenData
          }

          variants {
            name
            tag

            list {
              img {
                asset {
                  metadata {
                    dimensions {
                      height
                      width
                    }
                  }
                  url
                  fluid(maxWidth: 800) {
                    base64
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                  }
                  fixed(height: 50, width: 50) {
                    base64

                    width
                    height
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                  }
                }
                hotspot {
                  height
                  width
                  x
                  y
                }
              }

              quantityOptions {
                active
                hiddenAddQuantity
                quantityStep
              }
              multimediaOptions {
                active
                fyuse
                youtube
                listImages {
                  asset {
                    fluid {
                      base64
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                    }
                  }
                }
              }

              description
              price
              tag
              title
              otherOptionsProduct {
                hiddenData
              }
            }
          }
        }
      }
    }
  `)
  const variantProcessor = product => {
    let newVariants = [{ ...product, count: 0 }]
    if (product.variants) {
      newVariants = [
        {
          ...product,
          tag: product.variants.tag,
          name: product.variants.name,
          count: 0,
        },
      ]

      product.variants.list.forEach(variant => {
        let ProcessedVariant = {}
        Object.keys(variant).forEach((key, i) => {
          if (Object.values(variant)[i])
            ProcessedVariant[key] = Object.values(variant)[i]
        })
        newVariants.push({
          ...newVariants[0],
          ...ProcessedVariant,
        })
      })
    }
    return newVariants
  }
  const encodeCartOrder = cart => {
    const cartResume = cart.map((product, i) => {
      const item = {
        id: product.id,
        order: product.order.map(({ tag, count }) => ({ tag, count })),
      }

      return item
    })
    return cartResume
  }
  const decodeCartOrder = cart => {
    let newCart = []
    cart.forEach(({ id, order }) => {
      const matchProduct = search(allSanityProduct.nodes, "id", id)
      if (matchProduct) {
        let newOrder = []
        const variants = variantProcessor(matchProduct)
        let totalCount = 0
        let totalPrice = 0
        order.forEach(({ tag, count }) => {
          const matchVariant = search(variants, "tag", tag)
          if (matchVariant) {
            newOrder.push({
              ...matchVariant,
              tag,
              count,
            })
            totalCount += count
            totalPrice += count * matchVariant.price
          }
        })
        newCart.push({
          ...matchProduct,
          totalPrice,
          totalCount,
          totalPriceWithCurrency: `${totalPrice}${sanitySetting.money}`,
          order: newOrder,
        })
      }
    })
    return newCart
  }
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (cart.length > 0) {
        const now = new Date()
        const newCart = JSON.stringify({
          cartResume: encodeCartOrder(cart),
          date: `${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()}`,
        })
        setDataGet({ ...dataGet, cart: btoa(newCart) })
        window.localStorage.setItem("lastOrder", newCart)
      }
    }
  }, [cart])
  useEffect(() => {
    if (typeof window !== "undefined") {
      const url = new URL(window.location.href)
      const newUserData =
        JSON.parse(window.localStorage.getItem("userData")) || {}

      const cartUrl = url.searchParams.get("cart")
      const cartSave = url.searchParams.get("cartsave")
      setUserData(newUserData)
      if (cart.length == 0 && !cartUrl) {
        try {
          const lastOrder = JSON.parse(window.localStorage.getItem("lastOrder"))
          if (lastOrder && lastOrder.cartResume) {
            const newCart = decodeCartOrder(lastOrder.cartResume)
            if (newCart.length > 0) {
              setCart(newCart)
              setModalOpen(true)
            }
            if (lastOrder.date) {
              setLastDate(lastOrder.date)
            }
          }
        } catch (error) {
          console.error("No se pudo recuperar el carrito")
        }
      }
      if (cart.length == 0 && cartUrl) {
        try {
          const cartShare = JSON.parse(atob(cartUrl))

          if (
            typeof cartShare == "object" &&
            cartShare !== null &&
            cartShare.cartResume
          ) {
            const newCart = decodeCartOrder(cartShare.cartResume)
            if (newCart.length > 0) {
              setCart(newCart)
              setModalOpen(true)
            }
            if (cartShare.date) {
              setLastDate(cartShare.date)
            }
          }
        } catch (error) {
          console.error("No se pudo recuperar el carrito")
        }
      }

      if(cart.length == 0 && cartSave){
        console.log("cartSave:",cartSave)
      }

      //dataGet

      try {
        setDataGet({
          ...dataGet,
          message: url.searchParams.get("message") || "",
          phone: url.searchParams.get("phone") || "",
          cart: url.searchParams.get("cart") || "",
          search: url.search,
        })
      } catch (error) {
        console.error(error)
      }

      //Facebook Pixel
      try {
        if (
          sanityIntegration &&
          sanityIntegration.facebookPixel &&
          sanityIntegration.facebookPixel.pixelId
        ) {
          ReactPixel.init(sanityIntegration.facebookPixel.pixelId)
          ReactPixel.pageView()
        }
      } catch (error) {
        console.error(error)
      }
    }
  }, [])
  const _theme = theme(
    sanityUserInterface ? sanityUserInterface.customColors || "none" : "none"
  )
  return (
    <CartContext.Provider value={{ cart, setCart }}>
      <ThemeProvider theme={_theme}>
        <DataGetContext.Provider value={{ dataGet, setDataGet }}>
          {children}
        </DataGetContext.Provider>

        <Modal
          open={modalOpen}
          // onRequestClose={closeModal}
        >
          <div
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            {/* <div onClick={closeModal} sx={{ marginLeft: "auto" }}>
              <IoMdClose />
            </div> */}
            <div
              sx={{
                width: "auto",
                display: "flex",
                flexWrap: "wrap",
                maxHeight: "50vh",
                overflowY: "auto",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MiniImages cart={cart} />
            </div>
            <div>
              <Text variant="caption" sx={{ fontWeight: "bold" }}>
                ¿Restaurar Carrito de compras?
              </Text>
              {lastDate && (
                <Badge variant="primary" mb={2}>
                  {lastDate}
                </Badge>
              )}
              <Text variant="body">👋 Bienvenido de nuevo </Text>
            </div>
            <div
              sx={{
                display: "flex",
                marginTop: 3,
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Button
                onClick={closeModal}
                sx={{ fontSize: 2 }}
                variant="outlined"
                mr={2}
              >
                <IoMdClose /> Cancelar
              </Button>

              <Button
                onClick={() => {
                  setModalOpen(false)
                }}
                sx={{ fontSize: 2 }}
              >
                <FiShoppingCart sx={{ marginRight: 1 }} /> Restaurar
              </Button>
            </div>
          </div>
        </Modal>
      </ThemeProvider>
    </CartContext.Provider>
  )
}
